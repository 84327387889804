import React from 'react'
import WOW from 'wow.js'

class Contactame extends React.Component{

    componentDidMount(){
        new WOW().init()
    }

    render(){
        return(
            <section id="contactame" className="bg-primary d-flex align-items-center shadow-lg">
                <div className="capa-superior"></div>
                <div className="container my-4">
                    <div className="row d-flex justify-content-center">
                        <div className="card col-10 text-center">
                            <h3 className="wow bounceInRight">Contáctame</h3>
                            <div className="card-body row form-group d-flex justify-content-center">
                                <div className="col-12 col-sm-6">
                                    <input type="text" className="form-control mb-3 wow bounceInLeft" name="nombre" placeholder="Nombre"/>
                                    <input type="email" className="form-control mb-3 wow bounceInLeft" name="email" placeholder="Email"/>
                                    <input type="text" className="form-control mb-3 wow bounceInLeft" name="asunto" placeholder="Asunto" />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <textarea className="form-control mb-3 wow bounceInRight" placeholder="Mensaje" rows="5"></textarea>
                                </div>
                                <button type="submit" className="btn contactame  wow bounceInUp">Enviar</button>
                            </div>    
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default Contactame