import React from 'react'
import Navigation from '../components/Navigation'
import Intro from '../components/Intro'
import Acerca from '../components/Acerca'
import Servicios from '../components/Servicios'
import Proyectos from '../components/Proyectos'
import Contactame from '../components/Contactame'
import Footer from '../components/Footer'
import FlechaArriba from '../components/FlechaArriba'

class MainPage extends React.Component{
    
    render(){
        return(
            <React.Fragment>
                <Navigation/>
                <Intro/>
                <Acerca/>
                <Servicios/>
                <Proyectos/>
                <Contactame/>
                <Footer/>
                <FlechaArriba/>
            </React.Fragment>
        )
     }
}

export default MainPage