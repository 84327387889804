import React from 'react'
import OwlCarousel from 'react-owl-carousel'
import WOW from 'wow.js'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'

class Proyectos extends React.Component{

    componentDidMount(){
        new WOW().init();
    }

    render(){
        return(
            <section id="proyectos" className="text-center">
                <h2 className="mb-4 wow bounceInLeft">Proyectos Realizados</h2>
                <OwlCarousel
                    className="owl-theme mb-4 container"
                    loop
                    margin={0}
                    stagePadding={0}
                    nav
                    navText={['<i class="fa fa-arrow-circle-left">','<i class="fa fa-arrow-circle-right"></i>']}

                    responsive={{0:{items:1},
                    500:{
                        items:2,
                        margin:20,
                        stagePadding:10
                    }/*,
                    800:{
                        items:3,
                        margin:20,
                        stagePadding:10
                    },
                    1000:{
                        items:4,
                        margin:20,
                        stagePadding:10
                    }*/
                }}
                    
                >
                    <div className="item text-center">
                        <h2 className="titulo-trabajo"><p>Juego de Ajedrez para Desktop</p></h2>
                        <hr/>
                        <img src="images/ajedrez.jpg" alt="" />
                    </div>
                    <div className="item text-center">
                        <h2 className="titulo-trabajo"><p>Kosmogram</p></h2>
                        <hr/>
                        <img src="images/Kosmogram.jpg" alt="" />
                    </div>
                </OwlCarousel>
            </section>
        )
    }
}

export default Proyectos