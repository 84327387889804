import React from 'react'
import Typed from 'typed.js'
import WOW from 'wow.js'
import * as Scroll from 'react-scroll'

class Intro extends React.Component{

    state={text:['Kosmo', 'Freelancer','Desarrollador Fullstack^1000']}

    componentDidMount(){
        new WOW().init()

        const options = {
            strings: this.state.text, 
            typeSpeed: 120,
            loop: true,
            backSpeed: 30
        }
        var typed = new Typed(this.el, options)
        this.setState({
            test:typed
        })

        Scroll.Events.scrollEvent.register('begin', function(to, element) {
            console.log('begin', arguments)
            })
        
            Scroll.Events.scrollEvent.register('end', function(to, element) {
            console.log('end', arguments)
            })
        
            Scroll.scrollSpy.update()
    }

    scrollTo = e => {
        console.log(e.target.value)
        Scroll.scroller.scrollTo(e.target.value, {
            duration: 800,
            delay: 100,
            smooth: 'easeInOutCubic',
            offset: -50
        })
    }

    render(){
        return(
            <section id="intro" className="bg-dark d-flex justify-content-center text-center contenedor-header">
                <div className="container contenedor-intro d-flex justify-content-center">
                    <h1 className="display-4 wow bounceIn">
                        Kosmo Dev
                    </h1>
                    <p className="intro-text">
                        <span>
                            Yo soy <span ref={(el) => { this.el = el }}/>
                        </span>
                    </p>
                    <div><button value="contactame" onClick={this.scrollTo} className="contactame btn wow bounceIn">Contáctame</button></div>               
                </div>
                <div>
                    <button value="acerca" onClick={this.scrollTo} className="btn btn-secundary flechaBajar fa fa-angle-down"></button>
                </div>
            
            </section>
        )
    }
}

export default Intro