import React from 'react'
import * as Scroll from 'react-scroll'

class Navigation extends React.Component{

    state={
        flechaTB:'ion-arrow-down-b'
    }

    componentDidMount() {
        Scroll.Events.scrollEvent.register('begin', function(to, element) {
          console.log('begin', arguments)
        })
     
        Scroll.Events.scrollEvent.register('end', function(to, element) {
          console.log('end', arguments)
        })
     
        Scroll.scrollSpy.update()
      }
      componentWillUnmount() {
        Scroll.Events.scrollEvent.remove('begin')
        Scroll.Events.scrollEvent.remove('end')
      }


    handleClick=()=>{
        let flechaTB
        this.state.flechaTB==="ion-arrow-down-b"?flechaTB="ion-arrow-up-b":flechaTB="ion-arrow-down-b"
            this.setState({
                flechaTB
            })
    }

    scrollToTop(){
        Scroll.animateScroll.scrollToTop()
    }
    scrollTo = e => {
        console.log(e.target.value)
        Scroll.scroller.scrollTo(e.target.value, {
            duration: 800,
            delay: 100,
            smooth: 'easeInOutCubic',
            offset: -50
        })
            
    }
    

    render(){
        return(
            <header>
                <nav id="mainNav" className="navbar navbar-b navbar-expand-md fixed-top" role="navigation">
                    <div className = "container"> 
                        <a className="navbar-brand logo" href="#page-top"><img src="images/Asset 1.svg" width="200px" height="30px" alt="KosmoDev"/></a>
                        <button onClick={this.handleClick} className={"navbar-toggler collapsed " + this.state.flechaTB} type="button" data-toggle="collapse" data-target="#navbarDefault"aria-controls="navbarDefault"aria-expanded="false"aria-label="Toggle navigation">
                                        
                        </button>
                        <div className="navbar-collapse collapse justify-content-end"id="navbarDefault">
                            <ul className="navbar-nav">
                                <li className="navbar-item"><button className="nav-link btn js-scroll" onClick={this.scrollToTop}>Inicio</button></li>
                                <li className="navbar-item"><button className="nav-link btn js-scroll" value="acerca" onClick={this.scrollTo}>Acerca</button></li>
                                <li className="navbar-item"><button className="nav-link btn js-scroll" value="servicios" onClick={this.scrollTo}>Servicios</button></li>
                                <li className="navbar-item"><button className="nav-link btn js-scroll" value="proyectos" onClick={this.scrollTo}>Proyectos</button></li>
                                <li className="navbar-item"><button className="nav-link btn js-scroll" value="contactame" onClick={this.scrollTo}>Contáctame</button></li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </header>
        )
    }
}

export default Navigation