import React from 'react'
import WOW from 'wow.js'

class Elemento extends React.Component{

    state={evento:""}

    componentDidMount(){
        new WOW().init()

        if(this.props.order==="1"){
            this.setState({evento:"bounceInLeft"})
        }else{
            this.setState({evento:"bounceInRight"})
        }
    }

    render(){
        return(
            <li className={"d-flex flex-column flex-sm-row align-items-center elemento wow "+this.state.evento}>
                <i className={"icono-servicio fa " + this.props.icon + " order-md-" + this.props.order}></i>
                <div className={"text-center text-md-"+this.props.text_align}>
                    <h5 className="font-weight-bold">{this.props.texto}</h5>
                    <p className="d-none d-md-block">{this.props.contenido}</p>
                </div>
            </li>
        )
    }
}

export default Elemento