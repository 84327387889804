import React from 'react'
import WOW from 'wow.js'

class ElementoF extends React.Component{

    componentDidMount(){
        new WOW().init()
    }
    
    render(){
        return(
            <div className="col-12 col-sm-3 text-center logros my-4 wow bounceInLeft" data-wow-delay={"0."+this.props.n+"s"}>
                <i className={"icono-f-servicio fa " + this.props.icon }></i>
                <h4>{this.props.numero}</h4>
                <span>{this.props.text}</span>
            </div>
        )
    }
}

export default ElementoF