import React from 'react'
import ElementoFServ from './ListaServicios/Elemento-F-Serv'
import Elemento from './ListaServicios/Elemento'
import WOW from 'wow.js'

class Servicios extends React.Component{

    componentDidMount(){
        new WOW().init();
    }

    render(){
        return(
            <React.Fragment>
                <section id="servicios">
                    <div className="container text-center">
                        <h3 className="mt-4 h2 font-weight-bold wow bounceIn" data-wow-duration="1.5s">Servicios</h3>
                        <div className="row">
                            <ul className="col-6 col-lg-5 d-flex flex-column">
                                <Elemento texto="Páginas web" icon="fa-briefcase" text_align="left" order="1"
                                contenido="Desarrollo páginas web personalizadas a profesionales y empresas." />
                                <Elemento texto="Software de escritorio" icon="fa-bullhorn" text_align="left" order="1"
                                contenido="Desarrollo aplicaciones de escritorio de soporte a páginas web en cualquier sistema operativo." />
                            </ul>
                            <ul className="col-6 col-lg-5 offset-lg-2 d-flex flex-column">
                                <Elemento texto="Mantenimiento" icon="fa-cogs" text_align="right"
                                contenido="Proporciono mantenimiento a sitios web y a aplicaciones de escritorio." />
                                <Elemento texto="Videojuegos Android" icon="fa-calendar" text_align="right"
                                contenido="Brindo soporte y puedo participar como cooperador en vidiejuegos en la plataforma Andrid." />
                            </ul>
                        </div>
                    </div>
                </section>
                <section className="footer-servicios capa-inferior d-flex align-items-center mb-4">
                    <div className="capa-superior"></div>
                    <div className="container">
                        <div className="row">
                            <ElementoFServ icon="ion-checkmark-round" text="Trabajos Completados" numero="2" n="0" />
                            <ElementoFServ icon="ion-ios-calendar-outline" text="Años de experiencia" numero="1" n="2" />
                            <ElementoFServ icon="ion-ios-people" text="Total de clientes" numero="0" n="4" />
                            <ElementoFServ icon="ion-ribbon-a" text="Premios ganados" numero="0" n="6" />
                        </div>
                    </div>
                </section>
            </React.Fragment>
        )
    }
}

export default Servicios