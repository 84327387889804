import React from 'react'

class Footer extends React.Component{

    render(){
        return(
            <footer id="footer" className="d-flex align-items-center">
                <div className="text-center container">
                    <p className="derechos">© Copyright <b>Kosmo</b>. Todos los Derechos Reservados</p>
                </div>
            </footer>
        )
    }
}

export default Footer