import React from 'react'
import Scroll from 'react-scroll'

class FlechaArriba extends React.Component{

    componentDidMount() {
        Scroll.Events.scrollEvent.register('begin', function(to, element) {
          console.log('begin', arguments)
        })
     
        Scroll.Events.scrollEvent.register('end', function(to, element) {
          console.log('end', arguments)
        })
     
        Scroll.scrollSpy.update()
      }
      componentWillUnmount() {
        Scroll.Events.scrollEvent.remove('begin')
        Scroll.Events.scrollEvent.remove('end')
      }

      scrollToTop(){
        Scroll.animateScroll.scrollToTop()
    }

    render(){
        return(
            <button className="btn flecha-arriba" active onClick={this.scrollToTop}><i className="fa fa-arrow-circle-up"></i></button>
        )
    }
}

export default FlechaArriba