import React from 'react'
import ProgressBar from './ProgressBar/ProgressBar'
import WOW from "wow.js"

class Acerca extends React.Component{

    componentDidMount(){
        new WOW().init()
    }

    render(){
        return(
            <section id="acerca">
                <div className="container d-flex align-items-center flex-column">
                    <div className="row">
                        <div className="col-12 col-md-6 mt-4">
                            <div className="d-flex flex-wrap flex-sm-nowrap">
                                <img src="images/programer.jpg" alt="" width="170px" height="170px" className="wow bounceInLeft" />
                                <p className="ml-2 mt-4">
                                    <b >Nombre:</b><span className="litle"> Kosmo</span><br/>
                                    <b>Perfil:</b><span className="litle"> Desarrollador Fullstack</span><br/>
                                    <b>Email:</b><span className="litle"> vladiotero19101916@gmail.com</span>
                                </p>
                            </div>
                            <div className="mb-4">
                                <h4 className="font-weight-bold my-4">Skill</h4>
                                <ProgressBar porciento="60" lang="HTML" />
                                <ProgressBar porciento="50" lang="CSS" />
                                <ProgressBar porciento="60" lang="Javascript" />
                                <ProgressBar porciento="70" lang="Java" />
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <h3 className="mt-4 h2 font-weight-bold wow bounceIn">Acerca de Kosmo</h3>
                            <br/>
                            <p className="text-justify mb-4 wow bounceInUp">
                                Soy un estudiante de Ingeniería en Sistemas con un año de experiencia en desarrollo tanto
                                de aplicaciones web como de escritorio. Tengo los conocimientos esenciales para desarrollar
                                una página web completa de acuerdo a sus necesidades y ademas crear su respectiva aplicación
                                de escritorio si es necesario.<br/><br/>
                                Tengo además un año de experiencia en desarrollo frontend con Javascript-React, backend con 
                                Javascript-Node-MySQL y desarrollo multiplataforma con Java, desarrollando con este programas
                                con una alta complejidad computacional y algorítmica con este.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default Acerca