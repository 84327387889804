import React from 'react'
import WOW from 'wow.js'

class ProgressBar extends React.Component{

    componentDidMount(){
        new WOW().init()
    }

    render(){
        return(
            <React.Fragment>
                <div className="d-flex justify-content-between">
                    <p className="mt-2">{this.props.lang}</p>
                    <p className="mt-2">{this.props.porciento}%</p>
                </div>
                <div className="progress">
                    <div className="progress-bar wow bounceInLeft" role="progressbar" 
                    style={{width: `${this.props.porciento+"%"}`}} aria-valuenow={this.props.porciento} 
                    aria-valuemin="0" aria-valuemax="100"/>
                </div>
            </React.Fragment>
        )
    }
}

export default ProgressBar